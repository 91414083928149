.landingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0 0;
}

.landingContainer__heading {
  font-weight: bold;
  font-size: 64px;
  margin: 0 0 20px;
}

.landingContainer__tagline {
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  margin: 0 0 40px;
  max-width: 550px;
  text-align: center;
}

.landingContainer__uploadButton {
  align-self: center;
  margin-bottom: 40px;
}

.landingContainer__previewBox {
  width: 100%;
  max-width: 400px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-bottom: 40px;
}

.landingContainer__footer {
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  text-align: center;
}
.landingContainer__footer a {
  color: #6165f9;
}

@media (max-width: 550px) {
  .landingContainer {
    align-items: flex-start;
    padding-top: 20px;
  }

  .landingContainer__heading {
    font-size: 48px;
  }

  .landingContainer__tagline {
    text-align: left;
    font-size: 16px;
    line-height: 22px;
  }
}
